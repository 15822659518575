<template>
    <div>
        <div v-if="isInventoryModuleAvailable()" id="m_user_profile_tab_1" :class="['tab-pane', currentTab === 'import-products' ? 'active show' : '']">
            <import-products v-if="currentTab === 'import-products'" />
        </div>
    </div>
</template>

<script>
import ImportProducts from '@/components/Inventory/ImportProducts.vue'
import Features from '@/services/Features'
import { FeatureInventory } from '@/constants'
import Access from '@/services/Access'

export default {
    name: 'settings.profile',
    components: {
        ImportProducts,
    },
    data() {
        return {
            currentTab: 'import-products',
        }
    },
    methods: {
        isInventoryModuleAvailable() {
            return Features.checkFeature(FeatureInventory)
                && Access.checkAccess('inventory', Access.LEVEL_READ)
        },
    },
}
</script>
